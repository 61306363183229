<template>
  <div>
    <div class="loginContainer">
      <div class="login-con">
        <div class="login-txt">欢迎登录</div>
        <el-form ref="form1" :model="dataModel" class="demo-form-inline">
          <el-form-item label="手机">
            <el-input
              maxlength="11"
              v-model="dataModel.userInfo.mobile"
              @keydown.enter.native="loginFN()"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input
              type="password"
              v-model="dataModel.userInfo.pwd"
              @keydown.enter.native="loginFN()"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <v-btn @click="loginFN()" class="white--text" color="blue" block>
              登陆
            </v-btn>
            <!-- <el-button type="primary" size="medium" @click="loginFN()">登录</el-button> -->
          </el-form-item>
        </el-form>
        <span class="forget" @click="forgetPwd">忘记密码</span>
        <span class="forget" @click="toReg">注册账号</span>
      </div>
    </div>
  </div>
</template>
<script>
import userApi from '@/request/user'
export default {
  data() {
    return {
      dataModel: {
        userInfo: {
          mobile: '',
          password: ''
        }
      },
      rules: {
        'userInfo.mobile': {
          type: 'string',
          required: true,
          message: '请填写手机号',
          trigger: ['blur', 'change']
        },
        'userInfo.pwd': {
          type: 'string',
          required: true,
          message: '请填写密码',
          trigger: ['blur', 'change']
        }
      }
    }
  },
  components: {},
  created() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    async loginFN() {
      if (
        this.dataModel.userInfo.mobile != '' &&
        this.dataModel.userInfo.pwd != ''
      ) {
        let res = await userApi.login(this.dataModel.userInfo)
        res = res.data
        if (res && res.code == 0) {
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          this.$router.push('/')
        } else if (res && res.code == 104) {
          this.$message.error('该手机号还未注册，请先注册')
        } else if (res && res.code == 105) {
          this.$message.error('密码错误')
        }
      } else {
        this.$message.error('请填写完整登录信息')
      }
    },
    toReg() {
      this.$router.push('/register')
    },
    forgetPwd() {
      this.$router.push('/forgot')
    }
  }
}
</script>
<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
}

.pointer {
  cursor: pointer;
}

.loginContainer {
  background: url('~@/assets/image/login.png') no-repeat;
  width: 100%;
  height: 100vh;
  background-size: 100% 100%;
  z-index: 999;
}

.login-con {
  position: absolute;
  right: 160px;
  top: 50%;
  -webkit-transform: translateY(-60%);
  transform: translateY(-60%);
  width: 400px;
  background-color: #ffffff;
  padding: 30px;
}

.login-txt {
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  margin-bottom: 40px;
}
.forget {
  cursor: pointer;
  // width: 100%;
  text-align: right;
  padding-left: 10px;
  box-sizing: border-box;
}
</style>
