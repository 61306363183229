<template>
  <div>
    <div class="top">
      <sub-menu curTab="activity"></sub-menu>
      <div class="title_bg">
        <div class="title_txt">园区活动</div>
      </div>
    </div>
    <div class="summitTitle">
      <div class="overviewTitle mt-6">产业峰会</div>
    </div>
    <div class="summit mt-8">
      <v-row>
        <v-col cols="6">
          <div class="itemBg">
            <img
              style="width: 100%"
              src="@/assets/image/photo/中欧青浦校友会.png"
            />
            <div class="txt-1 mt-4">中欧青浦校友活动照片</div>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="itemBg">
            <img
              style="width: 100%"
              src="@/assets/image/photo/碳中和项目启动会.png"
            />
            <div class="txt-1 mt-4">碳中和项目启动会</div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="activityTitle">
      <div class="overviewTitle mt-8">联建活动</div>
    </div>
    <div class="activity mt-8">
      <v-row>
        <v-col cols="6">
          <div class="itemBg">
            <img
              style="width: 100%"
              src="@/assets/image/photo/国家社保基金来访.png"
            />
            <div class="txt-1 mt-4">国家社保基金来访</div>
          </div>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-col cols="5">
              <div class="itemBg1">
                <img
                  style="width: 100%"
                  src="@/assets/image/photo/市金融局团工委活动.png"
                />
                <div class="txt-2 mt-3">市金融局团工委活动</div>
              </div>
            </v-col>
            <v-col cols="5" class="ml-14">
              <div class="itemBg1">
                <img
                  style="width: 100%"
                  src="@/assets/image/photo/金山法院来访.png"
                />
                <div class="txt-2 mt-3">金山法院来访</div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <div class="itemBg1">
                <img
                  style="width: 100%"
                  src="@/assets/image/photo/长三角三地人大主任参观金融产业园.png"
                />
                <div class="txt-2 mt-3">长三角三地人大主任参观金融产业园</div>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="itemBg1">
                <img
                  style="width: 100%"
                  src="@/assets/image/photo/商务委等来访.png"
                />
                <div class="txt-2 mt-3">商务委等来访</div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <div class="itemBg1">
            <img
              style="width: 100%"
              src="@/assets/image/photo/发改委来访.png"
            />
            <div class="txt-2 mt-3">发改委来访</div>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="itemBg1">
            <img
              style="width: 100%"
              src="@/assets/image/photo/市国资委领导来访.png"
            />
            <div class="txt-2 mt-3">市国资委领导来访</div>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="itemBg1">
            <img
              style="width: 100%"
              src="@/assets/image/photo/金融产业园党建联建.png"
            />
            <div class="txt-2 mt-3">金融产业园党建联建</div>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="itemBg1">
            <img
              style="width: 100%"
              src="@/assets/image/photo/上海科创企业上市服务联盟实训.png"
            />
            <div class="txt-2 mt-3">上海科创企业上市服务联盟实训</div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="leaderTitle">
      <div class="overviewTitle mt-8">领导调研</div>
    </div>
    <div class="leader mt-8">
      <v-row>
        <v-col cols="6">
          <div class="itemBg">
            <img
              style="width: 100%"
              src="@/assets/image/photo/市发改委调研园区.png"
            />
            <div class="txt-1 mt-4">市发改委调研园区</div>
          </div>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-col cols="5">
              <div class="itemBg1">
                <img
                  style="width: 100%"
                  src="@/assets/image/photo/长三角金融产业园开园活动领导致辞.png"
                />
                <div class="txt-2 mt-3">长三角金融产业园开园活动领导致辞</div>
              </div>
            </v-col>
            <v-col cols="5" class="ml-14">
              <div class="itemBg1">
                <img
                  style="width: 100%"
                  src="@/assets/image/photo/开园仪式.png"
                />
                <div class="txt-2 mt-3">开园仪式</div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <div class="itemBg1">
                <img
                  style="width: 100%"
                  src="@/assets/image/photo/市国资委领导调研.png"
                />
                <div class="txt-2 mt-3">市国资委领导调研</div>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="itemBg1">
                <img
                  style="width: 100%"
                  src="@/assets/image/photo/市金融局团工委活动.png"
                />
                <div class="txt-2 mt-3">市金融局团工委活动</div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <div class="itemBg1">
            <img
              style="width: 100%"
              src="@/assets/image/photo/市发改委党组学习调研.png"
            />
            <div class="txt-2 mt-3">市发改委党组学习调研</div>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="itemBg1">
            <img
              style="width: 100%"
              src="@/assets/image/photo/区商务委来访.png"
            />
            <div class="txt-2 mt-3">区商务委来访</div>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="itemBg1">
            <img
              style="width: 100%"
              src="@/assets/image/photo/区发改委、朱家角三八妇女节活动.png"
            />
            <div class="txt-2 mt-3">区发改委、朱家角三八妇女节活动</div>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="itemBg1">
            <img
              style="width: 100%"
              src="@/assets/image/photo/上海法学会金融法研究会.png"
            />
            <div class="txt-2 mt-3">上海法学会金融法研究会、上海联…</div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="companyTitle">
      <div class="overviewTitle mt-8">企业沙龙</div>
    </div>
    <div class="company mt-8">
      <v-row>
        <v-col cols="4">
          <div class="itemBg2">
            <img
              style="width: 100%"
              src="@/assets/image/photo/致凯资本项目沙龙.png"
            />
            <div class="txt-3 mt-3">致凯资本项目沙龙</div>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="itemBg2">
            <img
              style="width: 100%"
              src="@/assets/image/photo/致凯资本项目沙龙2.png"
            />
            <div class="txt-3 mt-3">致凯资本项目沙龙</div>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="itemBg2">
            <img
              style="width: 100%"
              src="@/assets/image/photo/致凯资本项目沙龙3.png"
            />
            <div class="txt-2 mt-3">致凯资本项目沙龙</div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="roadshowTitle">
      <div class="overviewTitle mt-8">路演融资</div>
    </div>
    <div class="roadshow mt-8">
      <v-row>
        <v-col cols="3">
          <div class="itemBg1">
            <img
              style="width: 100%"
              src="@/assets/image/photo/和谐汇一专场路演.png"
            />
            <div class="txt-2 mt-3">和谐汇一专场路演</div>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="itemBg1">
            <img
              style="width: 100%"
              src="@/assets/image/photo/青发投资专场路演.png"
            />
            <div class="txt-2 mt-3">青发投资专场路演</div>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="itemBg1">
            <img
              style="width: 100%"
              src="@/assets/image/photo/青发投资专场路演1.png"
            />
            <div class="txt-2 mt-3">青发投资专场路演</div>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="itemBg1">
            <img
              style="width: 100%"
              src="@/assets/image/photo/复旦数字医疗科技股份有限公司.png"
            />
            <div class="txt-2 mt-3">复旦数字医疗科技股份有限公司</div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="trainingTitle">
      <div class="overviewTitle mt-8">员工培训</div>
    </div>
    <div class="bg1 pb-16">
      <div class="training mt-8">
        <v-row>
          <v-col cols="4">
            <div class="itemBg2">
              <img
                style="width: 100%"
                src="@/assets/image/photo/上海农商银行分享交流.png"
              />
              <div class="txt-2 mt-3">上海农商银行分享交流</div>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="itemBg2">
              <img
                style="width: 100%"
                src="@/assets/image/photo/浦发银行交流.png"
              />
              <div class="txt-2 mt-3">浦发银行交流</div>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="itemBg2">
              <img
                style="width: 100%"
                src="@/assets/image/photo/崧源学堂.png"
              />
              <div class="txt-2 mt-3">崧源学堂：VIE专题</div>
            </div>
          </v-col>
        </v-row>
        <v-row class="my-8">
          <v-col cols="4">
            <div class="itemBg2">
              <img
                style="width: 100%"
                src="@/assets/image/photo/园区内训.png"
              />
              <div class="txt-2 mt-3">园区内训（商务+会务）</div>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="itemBg2">
              <img
                style="width: 100%"
                src="@/assets/image/photo/宁本投资培训.png"
              />
              <div class="txt-2 mt-3">宁本投资培训</div>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="itemBg2">
              <img
                style="width: 100%"
                src="@/assets/image/photo/大成律所培训活动.png"
              />
              <div class="txt-2 mt-3">大成律所培训活动</div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <footer-bottom></footer-bottom>
  </div>
</template>
<script>
import SubMenu from '@/components/SubMenu'
import FooterBottom from '@/components/FooterBottom'
export default {
  data() {
    return {}
  },
  components: {
    SubMenu,
    FooterBottom,
  },
  created() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style lang="scss" scoped>
.top {
  background: url('~@/assets/image/banner_yuanqu.png') no-repeat;
  background-size: 100% 100%;
  height: 480px;

  .title_bg {
    width: 1200px;
    margin: 110px auto;

    .title_txt {
      width: 192px;
      height: 72px;
      font-size: 48px;
      font-family: MicrosoftYaHeiUI;
      color: #181821;
      line-height: 72px;
    }
  }
}
.summitTitle {
  width: 1200px;
  margin: 0 auto;

  .overviewTitle {
    text-align: center;
    font-size: 48px;
    font-family: 'MicrosoftYaHei';
    color: #181821;
    line-height: 72px;
  }
}
.summit {
  width: 1200px;
  margin: 0 auto;
}
.activity {
  width: 1200px;
  margin: 0 auto;
}
.leader {
  width: 1200px;
  margin: 0 auto;
}
.company {
  width: 1200px;
  margin: 0 auto;
}
.roadshow {
  width: 1200px;
  margin: 0 auto;
}
.training {
  width: 1200px;
  margin: 0 auto;
}
.itemBg {
  width: 590px;
  height: 420px;
  background: #ffffff;
  box-shadow: 8px 8px 20px 0px rgba(147, 124, 105, 0.1),
    -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}
.itemBg1 {
  width: 285px;
  height: 200px;
  background: #ffffff;
  box-shadow: 8px 8px 20px 0px rgba(147, 124, 105, 0.1),
    -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}
.itemBg2 {
  width: 380px;
  height: 276px;
  background: #ffffff;
  box-shadow: 8px 8px 20px 0px rgba(147, 124, 105, 0.1),
    -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}
.txt-1 {
  font-size: 16px;
  font-family: MicrosoftYaHeiUI;
  color: #181821;
  line-height: 22px;
  margin: 0px 30px;
}
.txt-2 {
  font-size: 16px;
  font-family: MicrosoftYaHeiUI;
  color: #181821;
  line-height: 22px;
  margin-top: -10px;
  margin-left: 20px;
}
.txt-3 {
  font-size: 16px;
  font-family: MicrosoftYaHeiUI;
  color: #181821;
  line-height: 22px;
  margin-top: -10px;
  margin-left: 20px;
}
.activityTitle {
  width: 1200px;
  margin: 0 auto;

  .overviewTitle {
    text-align: center;
    font-size: 48px;
    font-family: 'MicrosoftYaHei';
    color: #181821;
    line-height: 72px;
  }
}
.leaderTitle {
  width: 1200px;
  margin: 0 auto;

  .overviewTitle {
    text-align: center;
    font-size: 48px;
    font-family: 'MicrosoftYaHei';
    color: #181821;
    line-height: 72px;
  }
}
.companyTitle {
  width: 1200px;
  margin: 0 auto;

  .overviewTitle {
    text-align: center;
    font-size: 48px;
    font-family: 'MicrosoftYaHei';
    color: #181821;
    line-height: 72px;
  }
}
.roadshowTitle {
  width: 1200px;
  margin: 0 auto;

  .overviewTitle {
    text-align: center;
    font-size: 48px;
    font-family: 'MicrosoftYaHei';
    color: #181821;
    line-height: 72px;
  }
}
.trainingTitle {
  width: 1200px;
  margin: 0 auto;

  .overviewTitle {
    text-align: center;
    font-size: 48px;
    font-family: 'MicrosoftYaHei';
    color: #181821;
    line-height: 72px;
  }
}
.bg1 {
  width: 100%;
  background: url('~@/assets/image/bottom.png') no-repeat;
  background-size: 100% 100%;
}
</style>
