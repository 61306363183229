<template>
  <div>
    <div class="top">
      <sub-menu curTab="news"></sub-menu>
      <div class="title_bg">
        <div class="title_txt">园区新闻</div>
      </div>
    </div>
    <div class="newTitle">
      <div class="overviewTitle mt-6">园区新闻</div>
    </div>
    <div class="new01">
      <v-row>
        <v-col
          cols="4"
          v-for="(item, index) in newsList"
          :key="item.link"
          :index="index"
        >
          <div class="newsAnnouncement">
            <img
              :src="item.cover"
              width="380px"
              height="260px"
              referrerPolicy="no-referrer"
            />
            <div class="newTitle">{{ item.title }}</div>
            <div class="newDec mt-8">
              <a :href="item.link" target="_black" class="del-underline">
                <div
                  class="download"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <span class="downTxt">立即查看</span>
                  <img
                    src="@/assets/image/ic_arrow.png"
                    style="height: 16px; width: 16px"
                  />
                </div>
              </a>
            </div>
          </div>
        </v-col>
        <!-- <v-col cols="4">
          <div class="newsAnnouncement">
            <img
              src="@/assets/image/new/new5.png"
              width="380px"
              height="260px"
            />
            <div class="newTitle">
              青浦：深抓绿色金融发展机遇，打造区域特色园区
            </div>
            <div class="newDec mt-8">
              <a
                href="https://mp.weixin.qq.com/s/xdcZDOG3SeIDJAFpcLu2vw"
                target="_black"
                class="del-underline"
              >
                <div
                  class="download"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <span class="downTxt">立即查看</span>
                  <img
                    src="@/assets/image/ic_arrow.png"
                    style="height: 16px; width: 16px"
                  />
                </div>
              </a>
            </div>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="newsAnnouncement">
            <img
              src="@/assets/image/new/new6.png"
              width="380px"
              height="260px"
            />
            <div class="newTitle">
              “长三角数字干线”建设启动会在长三角（上海）金融产业园召开！
            </div>
            <div class="newDec mt-8">
              <a
                href="https://mp.weixin.qq.com/s/YvZIqwSugj-w2CbuOjrtEg"
                target="_black"
                class="del-underline"
              >
                <div
                  class="download"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <span class="downTxt">立即查看</span>
                  <img
                    src="@/assets/image/ic_arrow.png"
                    style="height: 16px; width: 16px"
                  />
                </div>
              </a>
            </div>
          </div>
        </v-col> -->
      </v-row>
    </div>
    <footer-bottom></footer-bottom>
  </div>
</template>
<script>
// import NewSubMenu from '@/components/NewSubMenu';
import SubMenu from '@/components/SubMenu'

import FooterBottom from '@/components/FooterBottom'
import news from '@/data/news'
export default {
  data() {
    return {
      newsList: news,
    }
  },
  components: { SubMenu, FooterBottom },
  created() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style lang="scss" scoped>
.top {
  background: url('~@/assets/image/banner_new.png') no-repeat;
  background-size: 100% 100%;
  height: 480px;

  .title_bg {
    width: 1200px;
    margin: 110px auto;

    .title_txt {
      width: 192px;
      height: 72px;
      font-size: 48px;
      font-family: MicrosoftYaHeiUI;
      color: #181821;
      line-height: 72px;
    }
  }
}
.newTitle {
  width: 1200px;
  margin: 0 auto;

  .overviewTitle {
    text-align: center;
    font-size: 48px;
    font-family: 'MicrosoftYaHei';
    color: #181821;
    line-height: 72px;
  }
}

.new01 {
  display: flex;
  flex-wrap: wrap;
  width: 1200px;
  margin: 0 auto;
  margin-top: 30px;

  .newsAnnouncement {
    background: url('~@/assets/image/gonggao.png') no-repeat;
    width: 380px;
    height: 420px;

    .newTitle {
      width: 340px;
      height: 64px;
      font-size: 20;
      font-family: 'MicrosoftYaHei';
      color: #474747;
      line-height: 32px;
      padding: 20px 20px 10px 20px;
    }

    .newDec {
      width: 320px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;

      .download {
        width: 111px;
        height: 36px;
        background: linear-gradient(180deg, #f9f5f1 0%, #ffffff 100%);
        box-shadow: 8px 8px 20px 0px rgba(147, 124, 105, 0.1),
          -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
        border-radius: 2px;
        border: 2px solid #ffffff;

        .downTxt {
          font-size: 14px;
          font-family: 'MicrosoftYaHei';
          color: #805832;
          line-height: 36px;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
