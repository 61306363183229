<template>
  <div>
    <div class="activeIndustryChart mt-2" id="activeIndustryChart"></div>
  </div>
</template>
<script>
import HomeAPI from '@/request/home.js'
export default {
  data() {
    return {
      activeIndustryChart: null,
      option: {
        title: {
          text: '活跃行业趋势图',
          left: '0%',
        },
        tooltip: {
          show: true,
          trigger: 'axis',
        },
        legend: {
          y: 'bottom',
          left: '0%',
          width: '80%',
        },
        grid: {
          left: '-30%',
          right: '40%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          show: true,
          data: [],
        },
        yAxis: {
          type: 'value',
          show: false,
        },
        series: [
          {
            name: '企业服务',
            type: 'line',
            stack: 'Total',
            data: [],
          },
          {
            name: '医疗健康',
            type: 'line',
            stack: 'Total',
            data: [],
          },
          {
            name: '生产制造',
            type: 'line',
            stack: 'Total',
            data: [],
          },
          {
            name: '金融',
            type: 'line',
            stack: 'Total',
            data: [],
          },
        ],
      },
    }
  },
  components: {},
  created() {
    this.$nextTick(() => {
      this.initFN()
    })
  },
  mounted() {
    this.getChartData()
  },
  computed: {},
  watch: {},
  methods: {
    // 初始化
    initFN() {
      var that = this
      // 渲染图表
      this.activeIndustryChart = this.$echarts.init(
        document.getElementById('activeIndustryChart')
      )
      // 填充数据
      this.activeIndustryChart.setOption(this.option, true)
    },
    async getChartData() {
      const res = await HomeAPI.getChartData()
      if (res.status == 200) {
        this.option.xAxis.data = res.data.data.date
        this.option.series[0].data = res.data.data.list[0].list.money
        this.option.series[1].data = res.data.data.list[1].list.money
        this.option.series[2].data = res.data.data.list[2].list.money
        this.option.series[3].data = res.data.data.list[3].list.money
      }
      // 填充数据
      this.activeIndustryChart.setOption(this.option, true)
    },
  },
}
</script>
<style lang="scss" scoped>
.activeIndustryChart {
  width: 300px;
  height: 270px;
}
</style>
