
export default {
  // 配置显示在浏览器标签的title
  // 域名
  baseUrl: 'https://csjapi.qimingpian.cn/News/',

  BMUrl: 'http://system.yrdfp.com:8092/'
  // BMUrl: 'http://localhost:8092/'

}
