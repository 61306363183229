export default [
    {
        title:'长三角生态绿色一体化发展示范区上海证券交易所资本市场服务工作站入驻金融产业园',
        cover:"https://mmbiz.qpic.cn/mmbiz_jpg/cbA5fW4bCvNTeTxXjPQxRybKNAZDt0oC5b5wFluEcnUbmOlAP5rEaXpahGzLETnzcfChpibfdTiacPsYj90wemzg/640",
        link:"https://mp.weixin.qq.com/s/m1MhcLqKRnxMoXy0JK8QUQ"
    },
    {
        title:'镇企携手谋发展，深化合作促共赢',
        cover:"https://mmbiz.qpic.cn/mmbiz_jpg/g9TsMmL8JxY1e3ibWr3SVs45bNSW8nb9ia4cxAgzWb942v7shbMBunbFTC9Ss2VfCiaYiaHHhkVn1xv1Kfia1h8w4zg/640",
        link:"https://mp.weixin.qq.com/s/m9QEnXG0VzcUqPW1hShENw"
    },
    {
        title:'青浦区委副书记张权权调研青发集团',
        cover:"https://mmbiz.qpic.cn/mmbiz_jpg/g9TsMmL8Jxb85e4ZjMJ6MPKkFibQah7qs5qWt5LUOp4v3HuVtuQMu8MwGoluoKN5lt0gYlfpS54UoAzrz4dQR2Q/640",
        link:"https://mp.weixin.qq.com/s/ct1ZQ21EQqCJQ1o0XsZjNw"
    }
]