<template>
  <div>
    <swiper :options="swiperOption" ref="mySwiper">
      <swiper-slide>
        <div class="bg01">
          <header-menu></header-menu>
          <div class="top_title">
            <img src="@/assets/image/title.png" alt="" />
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="bg02">
          <div class="item02 pt-16">
            <v-row no-gutters justify="center">
              <v-col cols="1" class="px-0">
                <img src="@/assets/image/img_tzzx.png" alt="" />
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-8">
              <div class="itemLabel">
                <div class="ma-3">
                  <div class="title_bg1">
                    <div class="title_txt">实时投融资数据</div>
                  </div>
                  <div class="line-1"></div>
                  <v-row>
                    <v-col cols="2">
                      <active-industry></active-industry>
                    </v-col>

                    <v-col cols="9" class="ml-3">
                      <v-row no-gutters>
                        <v-col cols="4">
                          <span class="ml-8">本月投资事件总数</span>
                          <total-investment></total-investment>
                        </v-col>
                        <v-col cols="4">
                          <span class="ml-8">本月并购事件总数</span>
                          <total-events></total-events>
                        </v-col>
                        <v-col cols="4">
                          <span class="ml-10">本月IPO事件总数</span>
                          <total-ipo></total-ipo>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="mt-5">
                        <v-col cols="4">
                          <span class="ml-12">本月投资金额</span>
                          <investment-amount></investment-amount>
                        </v-col>
                        <v-col cols="4">
                          <span class="ml-8">本月并购总金额</span>
                          <total-amount></total-amount>
                        </v-col>
                        <v-col cols="4">
                          <span class="ml-10">本月IPO募资总额</span>
                          <total-fundraising></total-fundraising>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div class="new-b-2 ml-6">
                <div class="ma-3">
                  <div class="title_bg">
                    <div class="title_txt">快讯</div>
                  </div>
                  <div>
                    <vue-seamless-scroll
                      :data="newsList"
                      :class-option="classOptionTop"
                      class="newTop"
                    >
                      <el-timeline :reverse="reverse">
                        <el-timeline-item
                          v-for="news in newsList"
                          :key="news.name"
                          :timestamp="news.open_time"
                        >
                          {{ news.news_content }}
                        </el-timeline-item>
                      </el-timeline>
                    </vue-seamless-scroll>
                  </div>
                </div>
              </div>
            </v-row>
            <v-row no-gutters class="mt-8">
              <div class="new-b-1 mr-2">
                <div class="ma-3">
                  <div class="title_bg">
                    <div class="title_txt">国内资讯</div>
                  </div>
                  <div class="mt-4">
                    <vue-seamless-scroll
                      :data="cnRongziList"
                      :class-option="classOptionTop"
                      class="warpTop"
                    >
                      <v-row
                        no-gutters
                        class="mt-3"
                        v-for="rongzi in cnRongziList"
                        :key="rongzi.name"
                        @click="goDetail(rongzi.source)"
                      >
                        <v-col cols="3" class="mt-2">
                          <span
                            style="
                              font-size: 12px;
                              font-family: MicrosoftYaHeiUI;
                              color: #825a32;
                            "
                          >
                            {{ rongzi.date }}
                          </span>
                        </v-col>
                        <v-col cols="3">
                          <img
                            :src="rongzi.icon"
                            style="
                              width: 64px;
                              height: 48px;
                              display: block;
                              object-fit: contain;
                            "
                          />
                        </v-col>
                        <v-col cols="6">
                          <div style="display: flex; flex-wrap: wrap">
                            <span
                              style="
                                font-size: 16px;
                                font-family: MicrosoftYaHeiUI;
                                color: #181821;
                              "
                            >
                              {{ rongzi.product }}
                              <span
                                style="font-size: 12px; margin-left: 10px"
                                >{{ rongzi.hangye1 }}</span
                              >
                            </span>
                            <span class="advisory-right-content">
                              {{ rongzi.weiyu }}
                            </span>
                          </div>
                        </v-col>
                      </v-row>
                    </vue-seamless-scroll>
                  </div>
                </div>
              </div>
              <div class="new-b-1 mx-6">
                <div class="ma-3">
                  <div class="title_bg">
                    <div class="title_txt">国外资讯</div>
                  </div>
                  <div class="mt-4">
                    <vue-seamless-scroll
                      :data="enRongziList"
                      :class-option="classOptionTop"
                      class="warpTop"
                    >
                      <v-row
                        no-gutters
                        class="mt-3"
                        v-for="rongzi in enRongziList"
                        :key="rongzi.name"
                        @click="goDetail(rongzi.source)"
                      >
                        <v-col cols="3" class="mt-2">
                          <span
                            style="
                              font-size: 12px;
                              font-family: MicrosoftYaHeiUI;
                              color: #825a32;
                            "
                          >
                            {{ rongzi.date }}
                          </span>
                        </v-col>
                        <v-col cols="3">
                          <img
                            :src="rongzi.icon"
                            style="
                              width: 64px;
                              height: 48px;
                              display: block;
                              object-fit: contain;
                            "
                          />
                        </v-col>
                        <v-col cols="6">
                          <div style="display: flex; flex-wrap: wrap">
                            <span
                              style="
                                font-size: 16px;
                                font-family: MicrosoftYaHeiUI;
                                color: #181821;
                              "
                            >
                              {{ rongzi.product }}
                              <span
                                style="font-size: 12px; margin-left: 10px"
                                >{{ rongzi.hangye1 }}</span
                              >
                            </span>
                            <span class="advisory-right-content">
                              {{ rongzi.weiyu }}
                            </span>
                          </div>
                        </v-col>
                      </v-row>
                    </vue-seamless-scroll>
                  </div>
                </div>
              </div>
              <div class="new-b-1">
                <div class="ma-3">
                  <div class="title_bg">
                    <div class="title_txt">IPO早知道</div>
                  </div>
                  <div class="mt-4">
                    <vue-seamless-scroll
                      :data="ipoList"
                      :class-option="classOptionTop"
                      class="warpTop"
                    >
                      <v-row
                        no-gutters
                        class="mt-3"
                        v-for="ipo in ipoList"
                        :key="ipo.index"
                        @click="goDetail(ipo.link_url)"
                      >
                        <v-col cols="2">
                          <img
                            :src="ipo.img_link"
                            style="width: 100px; height: 72px"
                            class="ipo-news-son-left"
                          />
                        </v-col>
                        <v-col cols="7" class="ml-16">
                          <div
                            style="
                              font-size: 14px;
                              font-family: MicrosoftYaHeiUI;
                              color: #181821;
                            "
                          >
                            {{ ipo.news_title }}
                          </div>
                          <div>
                            <span
                              style="
                                font-size: 12px;
                                font-family: MicrosoftYaHeiUI;
                                color: #181821;
                              "
                            >
                              {{
                                ipo.product_list[0]
                                  ? ipo.product_list[0].project
                                  : ''
                              }}
                            </span>
                            <span
                              style="
                                font-size: 12px;
                                font-family: MicrosoftYaHeiUI;
                                color: #181821;
                              "
                            >
                              {{ ipo.create_time }}
                            </span>
                          </div>
                        </v-col>
                      </v-row>
                    </vue-seamless-scroll>
                  </div>
                </div>
              </div>
            </v-row>
          </div>
        </div>
      </swiper-slide>
      <!-- 新闻 -->
      <swiper-slide>
        <div class="bg03">
          <a name="news" id="news">
            <div class="item03 pt-160">
              <v-row no-gutters>
                <v-col cols="1" class="px-0">
                  <img src="@/assets/image/img_xwgg.png" alt="" />
                </v-col>
                <v-col cols="11" class="px-0">
                  <div class="newTab">
                    <div class="tab_nav">
                      <div v-for="(item, index) in navList" :key="index">
                        <div
                          :class="
                            isActive === index
                              ? 'active nav navTitle pointer'
                              : 'nav navTitle pointer'
                          "
                          @click="checked(index)"
                        >
                          {{ item.title }}
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <div class="new01" v-if="isActive == 0">
                <div
                  class="newsAnnouncement mr-5"
                  v-for="(item, index) in news_List"
                  :key="item.link"
                  :index="index"
                >
                  <img
                    :src="item.cover"
                    width="380px"
                    height="260px"
                    referrerPolicy="no-referrer"
                  />
                  <div class="newTitle">
                    {{ item.title }}
                  </div>
                  <div class="newDec mr-6">
                    <a :href="item.link" target="_black" class="del-underline">
                      <div
                        class="download"
                        style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <span class="downTxt">立即查看</span>
                        <img
                          src="@/assets/image/ic_arrow.png"
                          style="height: 16px; width: 16px"
                        />
                      </div>
                    </a>
                  </div>
                </div>
                <!-- <div class="newsAnnouncement mr-5">
                  <img
                    src="@/assets/image/new/new5.png"
                    width="380px"
                    height="260px"
                  />
                  <div class="newTitle">
                    青浦：深抓绿色金融发展机遇，打造区域特色园区
                  </div>
                  <div class="newDec mr-6">
                    <a
                      href="https://mp.weixin.qq.com/s/xdcZDOG3SeIDJAFpcLu2vw"
                      target="_black"
                      class="del-underline"
                    >
                      <div
                        class="download"
                        style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <span class="downTxt">立即查看</span>
                        <img
                          src="@/assets/image/ic_arrow.png"
                          style="height: 16px; width: 16px"
                        />
                      </div>
                    </a>
                  </div>
                </div>
                <div class="newsAnnouncement">
                  <img
                    src="@/assets/image/new/new6.png"
                    width="380px"
                    height="260px"
                  />
                  <div class="newTitle">
                    “长三角数字干线”建设启动会在长三角（上海）金融产业园召开！
                  </div>
                  <div class="newDec mr-5">
                    <a
                      href="https://mp.weixin.qq.com/s/YvZIqwSugj-w2CbuOjrtEg"
                      target="_black"
                      class="del-underline"
                    >
                      <div
                        class="download"
                        style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <span class="downTxt">立即查看</span>
                        <img
                          src="@/assets/image/ic_arrow.png"
                          style="height: 16px; width: 16px"
                        />
                      </div>
                    </a>
                  </div>
                </div> -->
              </div>
              <div class="new" v-if="isActive == 1">
                <div class="newItem">
                  <div class="newTitle">
                    “科创金融，合力共赢”|长三角金融产业园参加2022年中欧金融圈年会招商推…
                  </div>
                  <div class="newDec mt-3">
                    <div class="newDate ml-5">2021-09-14</div>
                    <a
                      class="del-underline pointer"
                      href="https://yrdfpfile.oss-cn-shanghai.aliyuncs.com/files/%E9%95%BF%E4%B8%89%E8%A7%92%E9%87%91%E8%9E%8D%E4%BA%A7%E4%B8%9A%E5%9B%AD%E5%85%B3%E4%BA%8E%E4%B8%A5%E6%A0%BC%E8%90%BD%E5%AE%9E%E7%96%AB%E6%83%85%E9%98%B2%E6%8E%A7%E7%9A%84%E9%80%9A%E7%9F%A52022.3.7.pdf"
                    >
                      <div class="download">
                        <span class="downTxt">下载文件</span>
                        <img
                          src="@/assets/image/down.png"
                          style="height: 16px; width: 16px"
                        />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </swiper-slide>
      <!-- 合作机构 -->
      <swiper-slide>
        <div class="bg04">
          <div class="item04">
            <div class="d-flex justify-center pt-100">
              <img src="@/assets/image/img_hzjg.png" alt="" />
            </div>
            <v-row justify="space-between" class="mt-16">
              <img class="media" src="@/assets/image/media/bussness-1@2x.png" />
              <img class="media" src="@/assets/image/media/bussness-2@2x.png" />
              <img class="media" src="@/assets/image/media/bussness-3@2x.png" />
              <img class="media" src="@/assets/image/media/bussness-4@2x.png" />
              <img class="media" src="@/assets/image/media/bussness-5@2x.png" />
            </v-row>
            <v-row justify="space-between" class="mt-10">
              <img class="media" src="@/assets/image/media/bussness-6@2x.png" />
              <img class="media" src="@/assets/image/media/business-7@2x.png" />
              <img class="media" src="@/assets/image/media/bussness-8@2x.png" />
              <img class="media" src="@/assets/image/media/bussness-9@2x.png" />
              <img
                class="media"
                src="@/assets/image/media/bussness-10@2x.png"
              />
            </v-row>
            <v-row justify="space-between" class="mt-10">
              <img
                class="media"
                src="@/assets/image/media/bussness-11@2x.png"
              />
              <img
                class="media"
                src="@/assets/image/media/bussness-12@2x.png"
              />
              <img
                class="media"
                src="@/assets/image/media/bussness-13@2x.png"
              />
              <img
                class="media"
                src="@/assets/image/media/bussness-14@2x.png"
              />
              <img
                class="media"
                src="@/assets/image/media/bussness-15@2x.png"
              />
            </v-row>
            <v-row justify="space-between" class="mt-10">
              <img
                class="media"
                src="@/assets/image/media/bussness-16@2x.png"
              />
              <img
                class="media"
                src="@/assets/image/media/bussness-17@2x.png"
              />
              <img
                class="media"
                src="@/assets/image/media/bussness-18@2x.png"
              />
              <img
                class="media"
                src="@/assets/image/media/bussness-19@2x.png"
              />
              <img
                class="media"
                src="@/assets/image/media/bussness-20@2x.png"
              />
            </v-row>
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <footer-bottom></footer-bottom>
  </div>
</template>
<script>
import HomeAPI from '@/request/home.js'
import HeaderMenu from '@/components/HeaderMenu'
import FooterBottom from '@/components/FooterBottom'
import vueSeamlessScroll from 'vue-seamless-scroll'
import ActiveIndustry from './charts/ActiveIndustry'
import TotalInvestment from './charts/TotalInvestment'
import InvestmentAmount from './charts/InvestmentAmount'
import TotalAmount from './charts/TotalAmount'
import TotalEvents from './charts/TotalEvents'
import TotalFundraising from './charts/TotalFundraising'
import TotalIpo from './charts/TotalIpo'
import news from '@/data/news'

export default {
  data() {
    return {
      isActive: 0,
      reverse: true,
      navList: [
        {
          index: 0,
          title: '园区新闻',
        },
        {
          index: 1,
          title: '园区公告',
        },
      ],
      news_List: news,
      swiperOption: {
        speed: 300,
        freeMode: true,
        freeModeSticky: true,
        autoHeight: true,
        direction: 'vertical',
        height: window.innerHeight,
        slidesPerView: 1,
        // spaceBetween: 30,
        mousewheel: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      ip: 'https://csjapi.qimingpian.cn/News',
      newsList: [],
      cnRongziList: [],
      enRongziList: [],
      ipoList: [],
      classOptionTop: {
        step: 0.2, // 数值越大速度滚动越快
        // limitMoveNum: 4, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        // waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      },
    }
  },
  created() {},
  components: {
    HeaderMenu,
    FooterBottom,
    vueSeamlessScroll,
    ActiveIndustry,
    TotalInvestment,
    InvestmentAmount,
    TotalAmount,
    TotalEvents,
    TotalIpo,
    TotalFundraising,
  },
  mounted() {
    this.getIpoList()
    this.getRongziNewsCn()
    this.getRongziNewsEn()
    this.getNewsList()
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper
    },
  },
  watch: {},
  methods: {
    checked(index) {
      this.isActive = index
    },
    async getIpoList() {
      let params = {
        page: 0,
        num: 10,
      }
      const res = await HomeAPI.getIpoList(params)
      if (res.status == 200) {
        this.ipoList = res.data.data.list
      }
    },
    async getNewsList() {
      let params = {
        page: 0,
        num: 10,
      }
      const res = await HomeAPI.getNewsList(params)
      if (res.status == 200) {
        this.newsList = res.data.data.list
      }
    },
    async getRongziNewsCn() {
      let data = {
        page: 0,
        num: 10,
        country: 'CN',
      }
      const res = await HomeAPI.getRongziNews(data)
      if (res.status == 200) {
        this.cnRongziList = res.data.data.list
      }
    },
    async getRongziNewsEn() {
      let data = {
        page: 0,
        num: 10,
        country: 'EN',
      }
      const res = await HomeAPI.getRongziNews(data)
      if (res.status == 200) {
        this.enRongziList = res.data.data.list
      }
    },
    goDetail(url) {
      window.open(url)
    },
  },
}
</script>
<style scoped lang="scss">
.swiper-slide {
  width: 100%;
  font-size: 18px;
  background: #fff;
}

.bg01 {
  background: url('~@/assets/image/bg/bg1.png') no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
}

.bg02 {
  background: url('~@/assets/image/bg/bg2.png') no-repeat;
  background-size: 100% 100%;

  height: 100vh;
}

.bg03 {
  background: url('~@/assets/image/bg/bg-3.png') no-repeat;
  background-size: 100% 100%;

  height: 100vh;
}

.bg04 {
  background: url('~@/assets/image/bg/bg4.png') no-repeat;
  background-size: 100% 100%;

  height: 100vh;
}

.top_title {
  position: absolute;
  left: 10%;
  top: 30%;
}

.media {
  width: 216px;
  height: 74px;
  background: #ffffff;
  box-shadow: 8px 8px 20px 0px rgba(147, 124, 105, 0.1),
    -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.item04 {
  background-color: transparent;
  width: 1200px;
  height: 1080px;
  margin: 0 auto;
}

.item02 {
  width: 1200px;
  margin: 0 auto;
}

.item03 {
  background-color: transparent;
  width: 1200px;
  margin: 0 auto;

  .newTab {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }

  .tab_nav {
    display: flex;
    justify-content: center;
    align-items: center;

    .nav {
      width: 130px;
      height: 40px;
      background: rgba(255, 255, 255, 0.3);
      border-radius: 4px;
      margin-right: 20px;
    }

    .navTitle {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #825a32;
      line-height: 40px;
      text-align: center;
    }
  }

  .active {
    margin-right: 20px;
    width: 130px !important;
    height: 40px !important;
    background: #825a32 !important;
    border-radius: 4px !important;
    color: #ffffff !important;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
  }

  .active::after {
    content: '';
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
  }
}

.new {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;

  .newItem {
    background: url('~@/assets/image/gonggao-bg.png') no-repeat;
    width: 380px;
    height: 166px;
    margin-bottom: 20px;

    .newTitle {
      width: 330px;
      height: 64px;
      font-size: 18px;
      font-family: 'MicrosoftYaHei';
      color: #474747;
      line-height: 32px;
      padding: 20px 20px 10px 20px;
    }

    .newDec {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-right: 40px;
      align-items: center;

      .newDate {
        width: 137px;
        height: 32px;
        font-size: 14px;
        font-family: 'MicrosoftYaHei';
        color: #979797;
        line-height: 32px;
      }

      .download {
        width: 111px;
        height: 36px;
        background: linear-gradient(180deg, #f9f5f1 0%, #ffffff 100%);
        box-shadow: 8px 8px 20px 0px rgba(147, 124, 105, 0.1),
          -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
        border-radius: 2px;
        border: 2px solid #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;

        .downTxt {
          font-size: 14px;
          font-family: 'MicrosoftYaHei';
          color: #805832;
          line-height: 36px;
          margin-right: 10px;
        }
      }
    }
  }
}

.new01 {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;

  .newsAnnouncement {
    background: url('~@/assets/image/gonggao.png') no-repeat;
    width: 380px;
    height: 420px;

    .newTitle {
      width: 340px;
      height: 64px;
      font-size: 20;
      font-family: 'MicrosoftYaHei';
      color: #474747;
      line-height: 32px;
      padding: 20px 20px 10px 20px;
    }

    .newDec {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;

      .download {
        width: 111px;
        height: 36px;
        background: linear-gradient(180deg, #f9f5f1 0%, #ffffff 100%);
        box-shadow: 8px 8px 20px 0px rgba(147, 124, 105, 0.1),
          -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
        border-radius: 2px;
        border: 2px solid #ffffff;

        .downTxt {
          font-size: 14px;
          font-family: 'MicrosoftYaHei';
          color: #805832;
          line-height: 36px;
          margin-right: 10px;
        }
      }
    }
  }
}

.title_bg1 {
  width: 160px;
  height: 20px;
  background: linear-gradient(
    90deg,
    rgba(190, 133, 82, 0.5) 0%,
    rgba(190, 133, 82, 0) 100%
  );

  .title_txt {
    font-size: 18px;
    font-weight: bold;
    font-family: MicrosoftYaHeiUI;
    color: #181821;
    line-height: 22px;
    transform: translate(0px, -5px);
  }
}

.title_bg {
  width: 120px;
  height: 20px;
  background: linear-gradient(
    90deg,
    rgba(190, 133, 82, 0.5) 0%,
    rgba(190, 133, 82, 0) 100%
  );

  .title_txt {
    font-size: 18px;
    font-weight: bold;
    font-family: MicrosoftYaHeiUI;
    color: #181821;
    line-height: 22px;
    transform: translate(0px, -5px);
  }
}

.new-b-1 {
  width: 370px;
  height: 260px;
  background: #ffffff;
  box-shadow: 8px 8px 20px 0px rgba(147, 124, 105, 0.1),
    -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  border: 4px solid #ffffff;
}

.new-b-2 {
  width: 370px;
  height: 360px;
  background: #ffffff;
  box-shadow: 8px 8px 20px 0px rgba(147, 124, 105, 0.1),
    -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  border: 4px solid #ffffff;
}

.itemLabel {
  width: 780px;
  height: 360px;
  background: #ffffff;
  box-shadow: 8px 8px 20px 0px rgba(147, 124, 105, 0.1),
    -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  border: 4px solid #ffffff;
}

.line-1 {
  height: 1px;
  background: #e0e0e0;
  margin-top: 18px;
}

.line-2 {
  width: 1px;
  height: 200px;
  background: #e0e0e0;
  margin-top: 18px;
}

.advisory-right-content {
  font-size: 12px;
  font-family: MicrosoftYaHeiUI;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.warpTop {
  height: 210px;
  margin: 0 auto;
  overflow: hidden;
  margin-top: 15px;
}

.newTop {
  height: 300px;
  margin: 0 auto;
  overflow: hidden;
  margin-top: 10px;
}
</style>
