<template>
  <div>
    <div class="top">
      <sub-menu curTab="news"></sub-menu>
      <div class="title_bg">
        <div class="title_txt">园区公告</div>
      </div>
    </div>
    <div class="newTitle">
      <div class="overviewTitle mt-6">园区公告</div>
    </div>
    <div class="new">
      <v-row>
        <v-col cols="4">
          <div class="newItem px-6 pt-6">
            <div class="newTitle">
              长三角金融产业园关于严格落实疫情防控的通知
            </div>
            <div class="newDec mt-5">
              <div class="newDate">2022-03-07</div>
              <div class="download" @click="download">
                <span class="downTxt">下载文件</span>
                <img
                  src="@/assets/image/down.png"
                  style="height: 16px; width: 16px"
                />
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <footer-bottom class="pt-160"></footer-bottom>
  </div>
</template>
<script>
import SubMenu from '@/components/SubMenu'
import FooterBottom from '@/components/FooterBottom'
export default {
  data() {
    return {}
  },
  components: { SubMenu, FooterBottom },
  created() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    download() {
      let a = document.createElement('a')
      a.href =
        'https://yrdfpfile.oss-cn-shanghai.aliyuncs.com/files/%E9%95%BF%E4%B8%89%E8%A7%92%E9%87%91%E8%9E%8D%E4%BA%A7%E4%B8%9A%E5%9B%AD%E5%85%B3%E4%BA%8E%E4%B8%A5%E6%A0%BC%E8%90%BD%E5%AE%9E%E7%96%AB%E6%83%85%E9%98%B2%E6%8E%A7%E7%9A%84%E9%80%9A%E7%9F%A52022.3.7.pdf'
      a.click()
    },
  },
}
</script>
<style lang="scss" scoped>
.top {
  background: url('~@/assets/image/banner_new.png') no-repeat;
  background-size: 100% 100%;
  height: 480px;

  .title_bg {
    width: 1200px;
    margin: 110px auto;

    .title_txt {
      width: 192px;
      height: 72px;
      font-size: 48px;
      font-family: MicrosoftYaHeiUI;
      color: #181821;
      line-height: 72px;
    }
  }
}
.newTitle {
  width: 1200px;
  margin: 0 auto;

  .overviewTitle {
    text-align: center;
    font-size: 48px;
    font-family: 'MicrosoftYaHei';
    color: #181821;
    line-height: 72px;
  }
}

.new {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;

  .newItem {
    background: url('~@/assets/image/gonggao-bg.png') no-repeat;
    width: 380px;
    height: 166px;
    margin-bottom: 20px;

    .newTitle {
      width: 330px;
      height: 64px;
      font-size: 18px;
      font-family: 'MicrosoftYaHei';
      color: #474747;
      line-height: 32px;
    }

    .newDec {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-right: 15px;
      align-items: center;

      .newDate {
        width: 137px;
        height: 32px;
        font-size: 14px;
        font-family: 'MicrosoftYaHei';
        color: #979797;
        line-height: 32px;
      }

      .download {
        cursor: pointer;
        width: 111px;
        height: 36px;
        background: linear-gradient(180deg, #f9f5f1 0%, #ffffff 100%);
        box-shadow: 8px 8px 20px 0px rgba(147, 124, 105, 0.1),
          -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
        border-radius: 2px;
        border: 2px solid #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;

        .downTxt {
          font-size: 14px;
          font-family: 'MicrosoftYaHei';
          color: #805832;
          line-height: 36px;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
