<template>
  <v-app>
    <div id="app">
      <router-view />
    </div>
    <!--      <app-footer></app-footer> -->
  </v-app>
</template>

<script>
import Vue from 'vue'
// import AppFooter from "@/components/FooterBottom.vue";

export default {
  name: 'app',
  data: function () {
    return {}
  },
  components: {
    // AppFooter
  },
  mounted() {},
  methods: {},
}
</script>
<style>
html {
  overflow-x: hidden;
}

.v-application--wrap {
  overflow: hidden;
  position: relative;
  transform-origin: 0 0;
}

#app {
  /* background: url('./assets/bgD.png');
    background-size: contain; */
  /* min-width: 1920px; */
  overflow: hidden;
  position: relative;
  transform-origin: 0 0;
  background: #f5f4f1;
}

::-webkit-scrollbar {
  display: none !important;
}

#app .v-application--wrap::-webkit-scrollbar {
  display: none;
}

.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #825a32;
}

/* .swiper-container {
  width: 100%;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
} */
</style>
