<template>
  <div>
    <div class="head_navbar">
      <div class="header">
        <div>欢迎来到长三角一体化示范区（上海）金融产业园</div>
        <div
          class="login-info-left"
          style="display: flex; flex-wrap: nowrap"
          v-if="!userInfo"
        >
          <span @click="goLogin()" class="pointer">登录</span>

          <div style="margin: 0px 12px">|</div>
          <span @click="goRegister()" class="pointer">注册</span>
        </div>
        <div
          class="login-info-left"
          style="display: flex; flex-wrap: nowrap"
          v-if="userInfo"
        >
          <span>您好！{{ userInfo.enterpriseName }}</span>

          <div style="margin: 0px 12px">|</div>
          <span @click="logout" class="pointer">退出</span>
        </div>
      </div>
    </div>
    <div class="top_main pt-2">
      <div class="top_menu pointer">
        <router-link to="/" class="del-underline">
          <img
            src="@/assets/image/logo-1.png"
            style="width: 95px; height: 48px"
          />
        </router-link>
        <span class="mt-3">
          <router-link to="/" class="del-underline black--text">
            首页
          </router-link>
        </span>
        <span :class="'mt-3 ' + (curTab == 'about' ? 'menu_txt' : '')">
          <router-link to="/about" class="del-underline black--text">
            关于我们
          </router-link>
        </span>
        <span
          :class="'mt-3 del-underline' + (curTab == 'news' ? 'menu_txt' : '')"
        >
          <v-menu open-on-hover offset-y origin="left top">
            <template v-slot:activator="{ on }">
              <!-- <v-btn
                text
                tile
                v-on="on"
                class="text-none black--text fs-18"
                style="padding: 0"
              >
                新闻公告
              </v-btn> -->
              <span
                v-on="on"
                :class="
                  'del-underline black--text ' +
                  (curTab == 'news' ? 'menu_bg' : '')
                "
              >
                新闻公告
              </span>
            </template>
            <div class="dropdown-content">
              <v-list class="dropdown-list">
                <v-list-item class="pointer">
                  <router-link to="/news" class="del-underline black--text">
                    <span class="pointer">
                      <span class="ml-3"> 园区新闻 </span>
                    </span>
                  </router-link>
                </v-list-item>
                <v-list-item class="pointer">
                  <router-link
                    to="/announcement"
                    class="del-underline black--text"
                  >
                    <span class="pointer">
                      <span class="ml-3"> 园区公告 </span>
                    </span>
                  </router-link>
                </v-list-item>
              </v-list>
            </div>
          </v-menu>
          <!-- <a href="#newsBoard" class="del-underline white--text">新闻公告</a> -->
        </span>
        <span class="mt-3 pointer black--text" @click="toCR"> 产融对接 </span>

        <span class="mt-3">
          <a
            href="http://system.yrdfp.com:8090/#/login"
            target="_blank"
            class="del-underline black--text"
          >
            智慧园区
          </a>
        </span>
        <span class="mt-3 black--text pointer" @click="toZC">
          <!-- <a
            href="http://policyservice.yrdfp.com/policy-matching/#/home?company=长三角一体化示范区（上海）金融产业园经济发展有限公司"
            target="_blank"
            class="del-underline white--text"
          > -->
          政策服务
          <!-- </a> -->
        </span>
        <span :class="'mt-3 ' + (curTab == 'activity' ? 'menu_txt' : '')">
          <router-link to="/activity" class="del-underline black--text">
            园区活动
          </router-link>
        </span>
        <span class="mt-3"
          ><a href="#contactUs" class="del-underline black--text">联系我们</a>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import userApi from '@/request/user'
export default {
  props: {
    curTab: String,
  },
  data() {
    return {
      userInfo: {},
    }
  },
  components: {},
  created() {
    const user = localStorage.getItem('userInfo')
    this.userInfo = user ? JSON.parse(user) : null
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    goLogin() {
      this.$router.push('/login')
    },
    goRegister() {
      this.$router.push('/register')
    },
    logout() {
      localStorage.removeItem('userInfo')
      this.$message({
        type: 'success',
        message: '已退出',
      })
      location.reload()
    },
    async toCR() {
      if (this.userInfo) {
        //todo
        const res = await userApi.getCRUrl({
          uid: this.userInfo.id,
        })

        if (res && res.data.code == 0) {
          window.open(res.data.data, '_blank')
        } else {
          this.$message('账号有误')
        }
      } else {
        window.open(
          'https://vip8.console.clickpaas.com/policy-matching/#/financialServices',
          '_blank'
        )
        // this.$message('请先登录')
        // this.$router.push('/login')
      }
    },
    async toZC() {
      if (this.userInfo) {
        //todo
        const res = await userApi.getZCUrl({
          uid: this.userInfo.id,
        })

        if (res && res.data.code == 0) {
          window.open(res.data.data, '_blank')
        } else {
          this.$message('账号有误')
        }
      } else {
        this.$message('请先登录')
        this.$router.push('/login')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
.head_navbar {
  position: relative;
  width: 100%;
  height: 50px;
  background: #825a32;
}

.header {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  font-size: 14px;
  line-height: 50px;
  color: #ffffff;

  .login-info-left {
    padding: 0 12px;
    color: #fff;
    cursor: pointer;
  }
}

.top_main {
  width: 1200px;
  margin: 0 auto;
  font-size: 18px;
  font-family: MicrosoftYaHeiUI;
  color: #ffffff;
  line-height: 22px;
  color: #ffffff;
  background-color: transparent;

  .top_menu {
    width: 900px;

    display: flex;
    justify-content: space-between;

    .menu_txt {
      font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
      font-weight: bold;
      color: #181821;
      line-height: 22px;
      font-size: 18px;
    }

    .menu_txt a {
      color: #ffffff;
      text-decoration: none;
      display: block;
      width: 74px;
      height: 14px;
      background-image: linear-gradient(
        90deg,
        #be8552 0%,
        rgba(190, 133, 82, 0) 100%
      );
    }
  }
}
.menu_bg {
  color: #ffffff;
  text-decoration: none;
  font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
  font-weight: bold;

  display: block;
  width: 74px;
  height: 14px;
  color: #181818;
  background-image: linear-gradient(
    90deg,
    #be8552 0%,
    rgba(190, 133, 82, 0) 100%
  );
}
</style>
