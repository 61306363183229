import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
  // theme: { dark: true },  黑主题
}


export default new Vuetify(opts,{
  icons: {
    iconfont: 'mdi', // 默认值 - 仅用于展示目的
  },
})
