import Vue from 'vue'
import App from './App'
import router from './router'
//vuetify
import vuetify from '@/plugins/vuetify' // path to vuetify expo
//ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'


// CSS
import '../static/css/common.css'

//轮播
//引入swiper
import VueAwesomeSwiper from 'vue-awesome-swiper';
// 引入swiper样式
import "swiper/dist/css/swiper.css";
//挂载swiper
Vue.use(VueAwesomeSwiper);
Vue.use(ElementUI);


//echarts
import * as echarts from 'echarts'

// Vue.prototype.$config = config

Vue.prototype.$echarts = echarts

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  vuetify,
  // components: { App },
  render: h => h(App)
  // template: '<App/>'
})
