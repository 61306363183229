import axios from '@/config/api.request'
import qs from 'qs'
// qs.stringfy() 将对象序列化成URL的形式
// https://www.cnblogs.com/edwardwzw/p/11694903.html

export default {
    getIpoList(params){
        return axios.request({
            url: '/getIpoList',
            cache: false,
            method: 'get',
            params: params
        });
    },
    getRongziNews(data){
      return axios.request({
          url: '/getRongziNews',
          method: 'post',
          cache: false,
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data: qs.stringify(data),   // 用 qs 将js对象转换为字符串 'name=edward&age=25'
      });
    },
    // getRongziNews(){
    //   return axios.request({
    //       url: '/getRongziNews',
    //       cache: false,
    //       method: 'POST'
    //       // params: params
    //   });
    // },
    // getRongziNews(params){
    //   return axios.request({
    //       url: '/getRongziNews?page='+params.page+'&num='+params.num+'&country='+params.country,
    //       method: 'get',
    //   });
    // },
    getNewsList(params){
      return axios.request({
          url: '/getNewsList',
          cache: false,
          method: 'get',
          params: params
      });
    },
    getNumData(){
      return axios.request({
          url: '/getNumData',
          cache: false,
          method: 'get'
      });
    },
    getChartData(){
      return axios.request({
          url: '/getChartData',
          cache: false,
          method: 'get'
      });
    }
}
