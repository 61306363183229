<template>
  <div>
    <a name="contactUs" id="contactUs" style="cursor: default">
      <div class="footer">
        <v-container class="py-0 px-0">
          <v-row class="v-r-w">
            <v-col cols="4">
              <v-row class="mt-3 d-flex align-center">
                <img
                  style="width: 58px; height: 29px"
                  src="@/assets/image/buttom-logo.png"
                />
                <div class="footerT mt-2 ml-3">长三角金融产业园</div>
              </v-row>
              <v-row class="mt-8">
                <div class="footerTxt">
                  地址：上海市青浦区沪青平公路6663~6665号
                </div>
              </v-row>
              <!-- <v-row class="mt-5">
                <div class="footerTxt">邮箱：info@yrdfp.com</div>
              </v-row> -->
              <v-row class="mt-10">
                <div class="footerTitle">服务热线</div>
              </v-row>
              <v-row class="mt-5">
                <div class="tel">021-59281188</div>
              </v-row>
            </v-col>
            <v-col cols="2">
              <v-row class="mt-5">
                <div class="footerTitle">支持服务</div>
              </v-row>
              <v-row class="mt-8">
                <router-link to="/about" class="del-underline">
                  <div class="footerTxt">关于我们</div>
                </router-link>
              </v-row>
              <v-row class="mt-5">
                <router-link to="/activity" class="del-underline">
                  <div class="footerTxt">园区活动</div>
                </router-link>
              </v-row>
              <v-row class="mt-5">
                <a
                  href="http://system.yrdfp.com:8090/"
                  class="del-underline"
                  target="_blank"
                >
                  <div class="footerTxt">智慧园区</div>
                </a>
              </v-row>
              <v-row class="mt-5">
                <a
                  href="http://policyservice.yrdfp.com/policy-matching/#/home?company=长三角一体化示范区（上海）金融产业园经济发展有限公司"
                  target="_blank"
                >
                  <div class="footerTxt">政策服务</div>
                </a>
              </v-row>
            </v-col>
            <v-col cols="2">
              <v-row class="mt-5">
                <div class="footerTitle">技术支持</div>
              </v-row>
              <v-row class="mt-8">
                <div class="footerTxt">碧茂信息</div>
              </v-row>
            </v-col>
            <v-col cols="3">
              <v-row class="mt-5">
                <div class="footerTitle">关注我们</div>
              </v-row>
              <v-row class="mt-8">
                <div class="footerTxt">
                  添加长三角金融产业园公众号，关注最新咨询服务
                </div>
              </v-row>
              <v-row class="mt-8">
                <img
                  style="width: 100px; height: 100px"
                  src="@/assets/image/wechat.png"
                />
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="copyRight">
        <div class="copyRight_txt">
          版权所有 © 2022 长三角金融产业园
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >沪ICP备2022002664号</a
          >
        </div>
      </div>
    </a>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  components: {},
  created() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 300px;
  background: url('~@/assets/image/bg_bottom.png') no-repeat;
  background-size: 100% 100%;

  .footerT {
    font-size: 16px;
    line-height: 29px;
    font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
    font-weight: bold;
    color: #876137;
  }

  .footerTxt {
    font-size: 12px;
    font-family: MicrosoftYaHeiUI;
    color: #181821;
    line-height: 15px;
    opacity: 0.45;
  }

  .footerTitle {
    font-size: 16px;
    font-family: MicrosoftYaHeiUI;
    color: #181821;
    line-height: 20px;
    font-weight: bold;
  }

  .tel {
    font-size: 28px;
    font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
    font-weight: bold;
    color: #825a32;
    line-height: 35px;
  }
}

.copyRight {
  width: 100%;
  height: 48px;
  background: #1c1815;

  .copyRight_txt {
    padding-top: 17px;
    height: 15px;
    font-size: 12px;
    font-family: MicrosoftYaHeiUI;
    color: #ffffff;
    line-height: 15px;
    display: flex;
    justify-content: center;
  }
}

.v-r-w {
  width: 1200px;
  margin: 0 auto;
}
</style>
