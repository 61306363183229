import axios from '@/config/request'
import qs from 'qs'
// qs.stringfy() 将对象序列化成URL的形式
// https://www.cnblogs.com/edwardwzw/p/11694903.html

export default {
  createCode (params) {
    return axios.request({
      url: '/officialWebsite/createCode',
      cache: false,
      method: 'get',
      params: params
    });
  },
  register (data) {
    return axios.request({
      url: '/officialWebsite/register',
      method: 'post',
      cache: false,
      data: data,   // 用 qs 将js对象转换为字符串 'name=edward&age=25'
    });
  },
  login (data) {
    return axios.request({
      url: '/officialWebsite/login',
      method: 'post',
      cache: false,
      data: data,   // 用 qs 将js对象转换为字符串 'name=edward&age=25'
    });
  },
  resetPwd (data) {
    return axios.request({
      url: '/officialWebsite/resetPwd',
      method: 'post',
      cache: false,
      data: data,   // 用 qs 将js对象转换为字符串 'name=edward&age=25'
    });
  },
  getZCUrl (params) {
    return axios.request({
      url: '/officialWebsite/getZCUrl',
      method: 'get',
      cache: false,
      params: params,   // 用 qs 将js对象转换为字符串 'name=edward&age=25'
    });
  },
  getCRUrl (params) {
    return axios.request({
      url: '/officialWebsite/getCRUrl',
      method: 'get',
      cache: false,
      params: params,   // 用 qs 将js对象转换为字符串 'name=edward&age=25'
    });
  },

}
