<template>
  <div>
    <div class="top">
      <sub-menu curTab="about"></sub-menu>
      <div class="title_bg">
        <div class="title_txt">关于我们</div>
      </div>
    </div>
    <div class="aboutTitle">
      <div class="overviewTitle mt-6">园区简介</div>
    </div>
    <div class="about mt-8">
      <v-row>
        <v-col cols="6">
          <div class="ml-10 mt-10">
            <div class="title_b">
              <div class="t_t_1">长三角金融产业园</div>
            </div>
          </div>
          <div class="s_t_b ml-12 mt-9">
            <div class="s_t ml-2">打造长三角创新经济新高地</div>
          </div>
          <div class="aboutTxt ml-12 mt-4">
            长三角一体化示范区(上海)金融产业园经济发展有限公司经长三角生态绿色一体化发展示范区执行委员会、上海市经信委、上海市金融监管局和青浦区人民政府批准，于2020年5月11日正式成立。金融产业园以集聚高端金融企业、担当优质项目与资本媒介服务平台、推动区域经济发展为使命，逐步实现类金融企业集聚，着力发展绿色经济，打造绿色创新发展新高地。后期规划将金融产业园做大做强做实，建设金融产业园核心区，推动基金、银行、保险等大型金融机构总部集聚核心区。逐步实现金融产业园“短期规模集聚、中期特色聚焦、长期产业外溢”的发展规划设想。
          </div>
          <!-- <div class="aboutTxt mt-4 ml-12">
            截止到目前园区集聚基金管理规模超千亿，累计入驻企业超百家，具有代表性的头部基金公司有上海正心谷投资管理有限公司、上海和谐汇一资产管理有限公司、上海用友产业投资管理有限公司、静瑞私募基金管理(上海)有限公司等。
          </div> -->
        </v-col>
        <v-col cols="5" class="pr-5">
          <img src="@/assets/image/jianjie.png" alt="" />
        </v-col>
      </v-row>
    </div>
    <div class="locationTitle mt-8">
      <div class="overviewTitle mt-6">区位优势</div>
    </div>
    <div class="location">
      <v-row>
        <v-col cols="6">
          <div>
            <div class="ml-10 mt-10">
              <div class="title_b">
                <div class="t_t_1">驾车出行</div>
              </div>
            </div>
            <div class="mt-10 pl-16">
              <v-row class="pl-4">
                <div class="min_1_b">
                  <div class="min_1_t ml-2">15min</div>
                </div>
                <div class="min_1_t_1 ml-8">上海虹桥国际机场</div>
              </v-row>
              <v-row class="mt-8 pl-4">
                <div class="min_2_b">
                  <div class="min_2_t ml-2">65min</div>
                </div>
                <div class="min_2_t_2 ml-8">上海浦东国际机场</div>
              </v-row>
              <v-row class="mt-8 pl-4">
                <div class="min_3_b">
                  <div class="min_3_t ml-2">80min</div>
                </div>
                <div class="min_3_t_3 ml-8">苏南硕放国际机场</div>
              </v-row>
              <v-row class="mt-8 pl-4">
                <div class="min_4_b">
                  <div class="min_4_t ml-2">80min</div>
                </div>
                <div class="min_4_t_4 ml-8">嘉兴机场</div>
              </v-row>
            </div>
            <div class="ml-10 mt-12">
              <div class="title_b">
                <div class="t_t_1">高铁出行</div>
              </div>
            </div>
            <div class="mt-10 pl-16">
              <v-row class="pl-4">
                <div class="min_1_b">
                  <div class="min_1_t ml-2">25min</div>
                </div>
                <div class="min_1_t_1 ml-8">苏州</div>
              </v-row>
              <v-row class="mt-8 pl-4">
                <div class="min_5_b">
                  <div class="min_5_t ml-2">50min</div>
                </div>
                <div class="min_5_t_5 ml-8">杭州</div>
              </v-row>
              <v-row class="mt-8 pl-4">
                <div class="min_6_b">
                  <div class="min_6_t ml-2">75min</div>
                </div>
                <div class="min_6_t_6 ml-8">南京</div>
              </v-row>
            </div>
          </div>
        </v-col>
        <v-col cols="6">
          <img
            src="@/assets/image/quwei.png"
            style="width: 600px; height: 380px"
          />
          <div class="location_txt">
            长三角金融产业园位于长三角一体化发展示范区先行启动区龙头镇——朱家角。园区区位优势明显，半小时可达虹桥交通枢纽，周边有沪渝高速、轨交17号线等主要交通要道，一小时交通圈覆盖杭州、宁波、苏州等长三角知名城市。
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="investTitle mt-8">
      <div class="overviewTitle mt-6">投资环境</div>
    </div>
    <div class="invest mt-8">
      <v-row justify="center">
        <v-col cols="5">
          <v-row class="d-flex align-center">
            <v-col cols="2">
              <img
                src="@/assets/image/ic_two.png"
                style="width: 100px; height: 100px"
              />
            </v-col>
            <v-col cols="9" class="ml-3">
              <div class="i-title">两大国家战略</div>
              <div class="i-txt mt-3">
                “中国国际进口博览会”<br />
                “长三角一体化发展国家战略”
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="5" class="ml-8">
          <v-row class="d-flex align-center">
            <v-col cols="2">
              <img
                src="@/assets/image/ic_hongqiao.png"
                style="width: 100px; height: 100px"
              />
            </v-col>
            <v-col cols="9" class="ml-8">
              <div class="i-title">虹桥国际开放枢纽建设</div>
              <div class="i-txt mt-3">
                到2035年，虹桥国际开放枢纽将
                全面建成，以此提升长三角区域的开放水平，着力打造国内大循环的一个关键节点、国内国际双循环的一个枢纽链接，增强国际竞争合作的新优势。
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="my-16" justify="center">
        <v-col cols="5">
          <v-row class="d-flex align-center">
            <v-col cols="2">
              <img
                src="@/assets/image/ic_qingpu.png"
                style="width: 100px; height: 100px"
              />
            </v-col>
            <v-col cols="9" class="ml-3">
              <div class="i-title">青浦新城建设</div>
              <div class="i-txt mt-3">
                青浦新城建设着重彰显“高颜值、最江南、创新核”优势，打造生态优势转化新标杆、绿色创新发展新高地、制度创新试验田、人与自然和谐宜居新典范。
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="5" class="ml-8">
          <v-row class="d-flex align-center">
            <v-col cols="2">
              <img
                src="@/assets/image/ic_hongqiao.png"
                style="width: 100px; height: 100px"
              />
            </v-col>
            <v-col cols="9" class="ml-8">
              <div class="i-title">共筑创新经济新高地</div>
              <div class="i-txt mt-3">
                长三角一体化示范区以水乡客厅为起点，逐步推进淀山湖世界级湖区建设。已落地的华为青浦研发中心项目总用地面积约2400亩，该项目将打造华为全球创新基地，推动长三角区域创新经济发展。
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="planTitle mt-8">
      <div class="overviewTitle mt-6">空间规划</div>
    </div>
    <div class="bg1 pb-16">
      <div class="mt-8 plan">
        <v-row justify="center" align="center" no-gutters>
          <v-col cols="2" class="mt-16 mr-12 ml-6">
            <div class="plan_txt">
              长三角金融产业园先行启动区位于沪青平公路 6665
              号，占地约30亩，总建筑面积1.17
              万平方米。配套高标准现代化办公室、多功能会议中心等办公设施，拥有食堂、咖啡厅、休闲空间等生活服务设施。
            </div>
            <!-- <div class="plan_txt">
              后期拟对朱家角工业园区内106亩土地进行收储、规划，建设金融产业园核心区。
            </div> -->
          </v-col>
          <v-col cols="8">
            <img
              src="@/assets/image/plan.png"
              style="width: 886px; height: 370px"
            />
          </v-col>
        </v-row>
      </div>
    </div>
    <footer-bottom></footer-bottom>
  </div>
</template>
<script>
import SubMenu from '@/components/SubMenu'
import FooterBottom from '@/components/FooterBottom'
export default {
  data() {
    return {}
  },
  components: {
    SubMenu,
    FooterBottom,
  },
  created() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style lang="scss" scoped>
.top {
  background: url('~@/assets/image/bannner_about.png') no-repeat;
  background-size: 100% 100%;
  height: 480px;

  .title_bg {
    width: 1200px;
    margin: 110px auto;

    .title_txt {
      width: 192px;
      height: 72px;
      font-size: 48px;
      font-family: MicrosoftYaHeiUI;
      color: #181821;
      line-height: 72px;
    }
  }
}

.aboutTitle {
  width: 1200px;
  margin: 0 auto;

  .overviewTitle {
    text-align: center;
    font-size: 48px;
    font-family: 'MicrosoftYaHei';
    color: #181821;
    line-height: 72px;
  }
}

.about {
  width: 1200px;
  height: 438px;
  background: #ffffff;
  box-shadow: 8px 8px 20px 0px rgba(147, 124, 105, 0.1),
    -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
  margin: 0 auto;

  .title_b {
    width: 140px;
    height: 20px;
    background: url('~@/assets/image/bg-title.png') no-repeat;

    .t_t_1 {
      width: 260px;
      height: 22px;
      font-size: 16px;
      font-family: MicrosoftYaHeiUI;
      color: #181821;
      line-height: 22px;
      transform: translate(10px, -5px);
    }
  }

  .s_t_b {
    width: 202px;
    height: 22px;
    background: rgba(128, 88, 50, 0.1);
    border-radius: 4px;

    .s_t {
      width: 144px;
      height: 20px;
      font-size: 12px;
      font-family: MicrosoftYaHeiUI;
      color: #805832;
      line-height: 22px;
    }
  }

  .aboutTxt {
    width: 500px;
    height: 160px;
    font-size: 12px;
    font-family: MicrosoftYaHeiUI;
    color: #69696e;
    line-height: 20px;
  }
}

.locationTitle {
  width: 1200px;
  margin: 0 auto;

  .overviewTitle {
    text-align: center;
    font-size: 48px;
    font-family: 'MicrosoftYaHei';
    color: #181821;
    line-height: 72px;
  }
}

.location {
  width: 1200px;
  height: 579px;
  background: #ffffff;
  margin: 40px auto;
  box-shadow: 8px 8px 20px 0px rgba(147, 124, 105, 0.1),
    -8px -8px 20px 0px rgba(255, 255, 255, 0.1);

  .title_b {
    width: 140px;
    height: 20px;
    background: url('~@/assets/image/bg-title.png') no-repeat;

    .t_t_1 {
      width: 260px;
      height: 22px;
      font-size: 16px;
      font-family: MicrosoftYaHeiUI;
      color: #181821;
      line-height: 22px;
      transform: translate(10px, -5px);
    }
  }

  .min_1_b {
    background: url('~@/assets/image/car-1.png') no-repeat;
    background-size: 100% 100%;
    width: 48px;
    height: 20px;

    .min_1_t {
      font-size: 12px;
      font-family: MicrosoftYaHeiUI;
      color: #ffffff;
      line-height: 20px;
    }

    .min_1_t_1 {
      font-size: 16px;
      font-family: MicrosoftYaHeiUI;
      color: #181821;
      line-height: 20px;
    }
  }

  .min_2_b {
    background: url('~@/assets/image/car-2.png') no-repeat;
    background-size: 100% 100%;
    width: 198px;
    height: 20px;

    .min_2_t {
      font-size: 12px;
      font-family: MicrosoftYaHeiUI;
      color: #ffffff;
      line-height: 20px;
    }

    .min_2_t_2 {
      font-size: 16px;
      font-family: MicrosoftYaHeiUI;
      color: #181821;
      line-height: 20px;
    }
  }

  .min_3_b {
    background: url('~@/assets/image/car-3.png') no-repeat;
    background-size: 100% 100%;
    width: 243px;
    height: 20px;

    .min_3_t {
      font-size: 12px;
      font-family: MicrosoftYaHeiUI;
      color: #ffffff;
      line-height: 20px;
    }

    .min_3_t_3 {
      font-size: 16px;
      font-family: MicrosoftYaHeiUI;
      color: #181821;
      line-height: 20px;
    }
  }

  .min_4_b {
    background: url('~@/assets/image/car-4.png') no-repeat;
    background-size: 100% 100%;
    width: 243px;
    height: 20px;

    .min_4_t {
      font-size: 12px;
      font-family: MicrosoftYaHeiUI;
      color: #ffffff;
      line-height: 20px;
    }

    .min_4_t_4 {
      font-size: 16px;
      font-family: MicrosoftYaHeiUI;
      color: #181821;
      line-height: 20px;
    }
  }

  .min_5_b {
    background: url('~@/assets/image/railway2.png') no-repeat;
    background-size: 100% 100%;
    width: 153px;
    height: 20px;

    .min_5_t {
      font-size: 12px;
      font-family: MicrosoftYaHeiUI;
      color: #ffffff;
      line-height: 20px;
    }

    .min_5_t_5 {
      font-size: 16px;
      font-family: MicrosoftYaHeiUI;
      color: #181821;
      line-height: 20px;
    }
  }

  .min_6_b {
    background: url('~@/assets/image/railway3.png') no-repeat;
    background-size: 100% 100%;
    width: 228px;
    height: 20px;

    .min_6_t {
      font-size: 12px;
      font-family: MicrosoftYaHeiUI;
      color: #ffffff;
      line-height: 20px;
    }

    .min_6_t_6 {
      font-size: 16px;
      font-family: MicrosoftYaHeiUI;
      color: #181821;
      line-height: 20px;
    }
  }
}

.location_txt {
  width: 479px;
  height: 128px;
  font-size: 18px;
  font-family: MicrosoftYaHeiUI;
  color: #825a32;
  line-height: 32px;
}
.investTitle {
  width: 1200px;
  margin: 0 auto;

  .overviewTitle {
    text-align: center;
    font-size: 48px;
    font-family: 'MicrosoftYaHei';
    color: #181821;
    line-height: 72px;
  }
}
.invest {
  width: 1200px;
  margin: 0 auto;

  .i-title {
    width: 260px;
    height: 30px;
    font-size: 20px;
    font-family: MicrosoftYaHeiUI;
    color: #181821;
    line-height: 30px;
    font-weight: bold;
  }
  .i-txt {
    width: 360px;
    height: 48px;
    font-size: 14px;
    font-family: MicrosoftYaHeiUI;
    color: #181821;
    line-height: 24px;
  }
}
.planTitle {
  width: 1200px;
  margin: 0 auto;

  .overviewTitle {
    text-align: center;
    font-size: 48px;
    font-family: 'MicrosoftYaHei';
    color: #181821;
    line-height: 72px;
  }
}
.plan {
  width: 1200px;
  margin: 0 auto;
  .plan_txt {
    width: 260px;
    height: 160px;
    font-size: 12px;
    font-family: MicrosoftYaHeiUI;
    color: #69696e;
    line-height: 20px;
  }
}
.bg1 {
  width: 100%;
  background: url('~@/assets/image/diwen.png') no-repeat;
  background-size: 100% 100%;
}
</style>
