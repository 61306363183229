<template>
  <div>
    <div class="totalIpo" id="totalIpoChart"></div>
  </div>
</template>
<script>
import HomeAPI from '@/request/home.js'
export default {
  data() {
    return {
      totalIpoChart: null,
      option: {
        title: {
          text: '',
          left: 'center',
          y: 'bottom',
          textStyle: {
            color: '#35B457 ',
          },
        },
        tooltip: {
          show: true,
          trigger: 'axis',
        },
        // legend: {
        //   y: 'bottom',
        //   width: "80%"

        // },
        grid: {
          left: '10%',
          right: '10%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          show: false,
          data: [],
          // data:["10月", "11月", "12月", "1月", "2月", "3月"]
        },
        yAxis: {
          type: 'value',
          show: false,
        },
        series: [
          {
            name: '本月IPO事件总数',
            type: 'line',
            stack: 'Total',
            data: [],
            // data: [955, 1296, 2132, 1592, 1005, 1081]
          },
        ],
      },
    }
  },
  components: {},
  created() {
    this.$nextTick(() => {
      this.initFN()
    })
  },
  mounted() {
    this.getNumData()
  },
  computed: {},
  watch: {},
  methods: {
    // 初始化
    initFN() {
      var that = this
      // 渲染图表
      this.totalIpoChart = this.$echarts.init(
        document.getElementById('totalIpoChart')
      )
      // 填充数据
      this.totalIpoChart.setOption(this.option, true)
    },
    async getNumData() {
      const res = await HomeAPI.getNumData()
      if (res.status == 200) {
        this.option.title.text = res.data.data.list[2].num
        this.option.xAxis.data = res.data.data.list[2].month
        this.option.series[0].data = res.data.data.list[2].count
      }
      // 填充数据
      this.totalIpoChart.setOption(this.option, true)
    },
  },
}
</script>
<style lang="scss" scoped>
.totalIpo {
  width: 180px;
  height: 100px;
}
</style>
