<template>
  <div>
    <div class="loginContainer">
      <div class="login-con">
        <div class="login-txt">找回密码</div>
        <el-form ref="form1" :model="dataModel" :rules="rules">
          <el-form-item label="注册手机号" prop="userInfo.mobile">
            <el-input
              label-width="100px"
              v-model="dataModel.userInfo.mobile"
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="userInfo.pwd">
            <el-input
              v-model="dataModel.userInfo.pwd"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="userInfo.pwd2">
            <el-input
              v-model="dataModel.userInfo.pwd2"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="userInfo.code">
            <!-- <div style="display: flex; flex-direction: row"> -->
            <el-input v-model="dataModel.userInfo.code">
              <el-button type="primary" slot="suffix" @click="getCode"
                >获取验证码</el-button
              >
            </el-input>
            <!-- </div> -->
          </el-form-item>
          <slide-verify
            v-if="validBox"
            :l="42"
            :r="10"
            :w="350"
            :h="160"
            slider-text="安全检测，向右滑动"
            @success="onSuccess"
          ></slide-verify>
          <el-form-item class="mt-5">
            <v-btn @click="registerFN()" class="white--text" color="blue" block>
              重置密码
            </v-btn>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import SlideVerify from 'vue-monoplasty-slide-verify'
import userApi from '../../request/user'
Vue.use(SlideVerify)
export default {
  data() {
    return {
      validBox: false,
      valid: false,
      dataModel: {
        userInfo: {
          mobile: '',
          pwd: '',
          pwd2: '',
          code: ''
        }
      },
      rules: {
        'userInfo.mobile': [
          {
            type: 'string',
            required: true,
            message: '请填写手机号',
            trigger: ['blur', 'change']
          },
          { min: 11, max: 11, message: '请填写正确的手机号', trigger: 'blur' }
        ],
        'userInfo.pwd': [
          {
            type: 'string',
            required: true,
            message: '请填写密码',
            trigger: ['blur', 'change']
          },
          { min: 6, message: '密码必须6位以上', trigger: 'blur' }
        ],
        'userInfo.pwd2': [
          {
            type: 'string',
            required: true,
            message: '请填写确认密码',
            trigger: ['blur', 'change']
          },
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请再次输入密码'))
              } else if (value !== this.dataModel.userInfo.pwd) {
                callback(new Error('两次输入密码不一致!'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        'userInfo.enterpriseName': {
          type: 'string',
          required: true,
          message: '请填写公司名称',
          trigger: ['blur', 'change']
        },
        'userInfo.code': {
          type: 'string',
          required: true,
          message: '请填写验证码',
          trigger: ['blur', 'change']
        }
      }
    }
  },
  components: {},
  created() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    loginFN() {
      this.$router.push('/')
    },
    async registerFN() {
      this.$refs.form1.validate(async valid => {
        if (valid) {
          const res = await userApi.resetPwd(this.dataModel.userInfo)
          console.log(res)
          if (res && res.data.code == 0) {
            this.$message({
              type: 'success',
              message: '密码重置成功，请登陆'
            })
            this.$router.push('/login')
          } else if (res && res.data.code == 101) {
            this.$message.error('该手机未注册')
          } else if (res && res.data.code == 102) {
            this.$message.error('手机号验证码错误')
          } else if (res && res.data.code == 103) {
            this.$message.error('验证码错误')
          }
        }
      })
    },
    async getCode() {
      if (!this.valid) {
        this.validBox = true
      } else {
        this.validBox = false
        //todo send sms
        const res = await userApi.createCode({
          mobile: this.dataModel.userInfo.mobile
        })
        if (res.data.code == 0) {
          this.$message({
            type: 'success',
            message: '验证码已发送'
          })
        } else {
          this.$message.error('获取验证码失败')
        }
        console.log('成功了')
      }
    },

    onSuccess() {
      this.valid = true
      this.getCode()
    }
  }
}
</script>
<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
}

.pointer {
  cursor: pointer;
}

.loginContainer {
  background: url('~@/assets/image/login.png') no-repeat;
  width: 100%;
  height: 100vh;
  background-size: 100% 100%;
  z-index: 999;
}

.login-con {
  position: absolute;
  right: 160px;
  top: 50%;
  -webkit-transform: translateY(-60%);
  transform: translateY(-60%);
  width: 400px;
  background-color: #ffffff;
  padding: 30px;
}

.login-txt {
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  margin-bottom: 40px;
}
</style>
