import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home/Home'
import About from '@/views/about/About'
import Activity from '@/views/about/Activity'
import Announcement from '@/views/new/Announcement'
import News from '@/views/new/News'
import Login from '@/views/user/Login'
import Register from '@/views/user/Register'
import ResetPwd from '@/views/user/ResetPwd'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/about', name: 'About', component: About },
  { path: '/activity', name: 'Activity', component: Activity },
  { path: '/announcement', name: 'Announcement', component: Announcement },
  { path: '/news', name: 'News', component: News },
  { path: '/login', name: 'Login', component: Login },
  { path: '/register', name: 'Register', component: Register },
  { path: '/forgot', name: 'ForgetPwd', component: ResetPwd },
]

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // 切换页面滚动到页面顶部
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

// 以下代码解决路由地址重复的报错问题(一劳永逸)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
